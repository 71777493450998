import React from 'react';
import SortableTable from './SortableTable';
import './App.css';

function App() {
  return (
    <div className="App">
      <SortableTable />
    </div>
  );
}

export default App;

import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import axios from 'axios';
import Papa from 'papaparse';
import { Search } from '@icon-park/react';
import './App.css';

const fetchData = async (csvUrl) => {
  const response = await axios.get(`/api/proxy?csvUrl=${csvUrl}`);
  return new Promise((resolve, reject) => {
    Papa.parse(response.data, {
      header: true,
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

const SortableTable = ({ csvUrl }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGE_TITLE; // 使用环境变量设置页面标题
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedData = await fetchData(csvUrl);
        setData(fetchedData);
        setFilteredData(fetchedData);
      } catch (error) {
        console.error('Error loading the CSV file:', error);
      }
    };

    loadData();
  }, [csvUrl]);

  useEffect(() => {
    const lowercasedFilter = searchInput.toLowerCase();
    const filtered = data.filter(item => {
      return Object.keys(item).some(key =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredData(filtered);
  }, [searchInput, data]);

  const columns = useMemo(
    () => [
      {
        Header: '模型',
        accessor: '模型',
        disableSortBy: false, // 允许排序
      },
      {
        Header: '提供商',
        accessor: '提供商',
        disableSortBy: false, // 允许排序
      },
      {
        Header: '百万输入',
        accessor: '百万输入',
      },
      {
        Header: '百万输出',
        accessor: '百万输出',
      },
      {
        Header: '更新时间',
        accessor: '更新时间',
        disableSortBy: true,
      },
      {
        Header: '其他',
        accessor: '其他',
        disableSortBy: true,
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: filteredData }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="table-container">
      <div className="search-container">
        <Search className="search-icon" />
        <input
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search"
          className="search-input"
        />
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : column.disableSortBy
                        ? ''
                        : ' ⬍'}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const App = () => {
  const [activeTable, setActiveTable] = useState('hot.csv');

  return (
    <div className="container">
      <h1>{process.env.REACT_APP_MAIN_TITLE}</h1> {/* 使用环境变量设置主标题 */}
      <p>{process.env.REACT_APP_DESCRIPTION}</p> {/* 显示描述文本 */}
      <div className="button-container">
        <button
          onClick={() => setActiveTable('hot.csv')}
          className={activeTable === 'hot.csv' ? 'active' : ''}
        >
          热门
        </button>
        <button
          onClick={() => setActiveTable('detailed.csv')}
          className={activeTable === 'detailed.csv' ? 'active' : ''}
        >
          详细
        </button>
      </div>
      <SortableTable csvUrl={activeTable} />
    </div>
  );
};

export default App;
